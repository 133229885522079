import "./diagram1v2.scss"

import React from "react"

import t from "locale"

const Diagram1 = ({ lang }) => {
  return (
    <svg
      className="diagram-version-1v2"
      version="1.1"
      id="Warstwa_1"
      x="0px"
      y="0px"
      viewBox="0 0 336.1 260.1"
    >
      <switch>
        <g>
          <rect x="22.1" y="209.6" class="st0" width="302.2" height="0.7" />
          <rect x="22.3" y="89.4" class="st1" width="302.2" height="0.7" />
          <rect x="22.3" y="169.7" class="st1" width="302.2" height="0.7" />
          <rect
            x="238.8"
            y="191.7"
            class="st2 animated-bar animated-bar--3"
            width="27.8"
            height="17.9"
          />
          <rect x="22.3" y="129.5" class="st1" width="302.2" height="0.7" />
          <rect x="21.5" y="89.4" class="st0" width="0.8" height="120.9" />
          <text
            transform="matrix(1.0134 0 0 1 8.1494 92.0198)"
            class="st3 st4 st5"
          >
            40
          </text>
          <text
            transform="matrix(1.0134 0 0 1 8.8516 132.0746)"
            class="st3 st4 st5"
          >
            25
          </text>
          <text
            transform="matrix(1.0134 0 0 1 9.5537 172.2661)"
            class="st3 st4 st5"
          >
            15
          </text>
          <text
            transform="matrix(1.0134 0 0 1 12.3843 210.8623)"
            class="st3 st4 st5"
          >
            0
          </text>
          <rect
            x="160.2"
            y="177.5"
            class="st6 animated-bar animated-bar--2"
            width="27.8"
            height="32.1"
          />
          <rect
            x="82.1"
            y="92.6"
            class="st6 animated-bar animated-bar--1"
            width="27.8"
            height="117"
          />
          <text
            transform="matrix(1.0134 0 0 1 243.0374 184.6687)"
            class="st2 st4 st7 animated-text"
          >
            6.9*
          </text>
          <text
            transform="matrix(1.0134 0 0 1 164.7614 170.4975)"
            class="st6 st4 st7 animated-text"
          >
            12.2
          </text>
          <text
            transform="matrix(1 0 0 1 89.844 85.582)"
            class="st6 st8 st9 animated-text"
          >
            39
          </text>
          <g>
            <text
              transform="matrix(1 0 0 1 81.6992 36.1031)"
              class="st10 st4 st7"
            >
              CO
            </text>
            <text
              transform="matrix(0.583 0 0 0.583 97.2192 39.4332)"
              class="st10 st4 st7"
            >
              2
            </text>
            <text
              transform="matrix(1 0 0 1 100.5131 36.1031)"
              class="st10 st4 st7"
            >
              {t("-emissions from meat production", lang)}
            </text>
          </g>
          <rect x="26.3" y="74.6" class="st11" width="51.7" height="29.4" />
          <text transform="matrix(1 0 0 1 21.9002 74.6)" class="st12 st4 st5">
            kg CO
          </text>
          <text
            transform="matrix(0.583 0 0 0.583 42.2352 76.931)"
            class="st12 st4 st5"
          >
            2
          </text>
          <text transform="matrix(1 0 0 1 44.5408 74.6)" class="st12 st4 st5">
            {" "}
            per kg
          </text>
          <text
            transform="matrix(1 0 0 1 21.9002 82.9999)"
            class="st12 st4 st5"
          >
            {t("edible meat", lang)}
          </text>
          <g>
            <path
              class="st13"
              d="M246.1,228.2c0-1.3-1.8-2.3-1.9-2.3l-0.8-0.5c-1.3,0.7-2.5,1.5-3.6,2.4c-0.2,0.2-0.3,0.5-0.1,0.7l0.1,0.1
				c1.1,0.9,2.3,1.7,3.5,2.3l0.8-0.5C244.5,230.4,246.1,229.4,246.1,228.2z"
            />
            <path
              class="st13"
              d="M263.8,225.1c-0.2-0.3-0.6-0.4-0.8-0.2c0,0,0,0-0.1,0l-2.8,2.4c-2.2-2-5-3.2-7.9-3.6
				c-2.5-0.4-5.1,0.1-7.6,1.1c0.5,0.3,0.9,0.6,1.3,0.9c0.7,0.6,1.2,1.5,1.3,2.4c0,1.9-2.3,3.1-2.4,3.2l-0.3,0.1c2.3,1,4.9,1.4,7.6,1
				c3-0.5,5.7-1.8,7.9-3.7l2.8,2.4c0.3,0.2,0.7,0.2,0.8-0.1c0,0,0-0.1,0.1-0.1C264.9,229.3,264.9,227,263.8,225.1z"
            />
            <path
              class="st13"
              d="M258.1,231.6c-2.4,1.4-5.2,2.1-8,2.1c0.6,0.7,1.4,1,2.2,1.1c1.3,0,2.7-0.3,3.8-0.9c1.2-0.6,2.2-1.1,2.3-1.1
				c0.2-0.1,0.4-0.4,0.2-0.7c0-0.1-0.1-0.1-0.1-0.2L258.1,231.6z"
            />
            <path
              class="st13"
              d="M255.3,222.2c0-0.3-0.2-0.5-0.5-0.5c-1.4-0.2-2.8,0-4,0.6c-0.2,0.1-0.3,0.2-0.5,0.4c1.7,0,3.4,0.3,4.9,0.8
				V222.2z"
            />
          </g>
          <g>
            <path
              class="st14"
              d="M93.5,234.9c0,0.1-0.1,0.2-0.1,0.3v3.8h-1.3v-7h2.8L93.5,234.9z"
            />
            <path
              class="st14"
              d="M104.2,233.6l-1.1-1.5h2.3l1.5,1.2l-2.9,5.8h-1.3l1.6-4.9C104.3,234,104.3,233.8,104.2,233.6z"
            />
            <path
              class="st14"
              d="M102.9,225c-1,0-1.9-0.8-1.9-1.9v-1.3c0-0.3-0.2-0.6-0.6-0.7l-1.2-0.2c-0.9-0.2-1.6-0.9-1.5-1.9v-1.8h7v5.7
				C104.8,224.3,104,225,102.9,225z"
            />
            <path class="st14" d="M79.5,217.5v1.3c-1,0-1.9-0.6-1.9-1.3H79.5z" />
            <path
              class="st14"
              d="M87.1,218.7c-0.4,0-0.7-0.1-1-0.2c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0.1-0.3,0.3-0.3,0.6v3.8L83,225h-2.3v-7.6
				H89C89,218.1,88.1,218.7,87.1,218.7z"
            />
            <path
              class="st14"
              d="M86.4,223.2v-3.3c0.2,0,0.5,0.1,0.7,0.1c1.8,0,3.2-1.1,3.2-2.5h6.3v1.8c0,1.5,1,2.8,2.5,3.1l0.7,0.1v0.7
				c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2v-5.7h1.3c1.8,0,3.2,1.4,3.2,3.2v10.8c0,0.1,0,0.2,0.1,0.3l1.2,2.4v4.9h-1.3v-3.8
				c0-0.2-0.1-0.4-0.2-0.5l-4.2-3.6v-0.4H90.9v0.4l-2.4,3c-0.1,0.1-0.2,0.3-0.2,0.4v4.5H87v-8.9c0-0.1,0-0.2-0.1-0.2L85,225v-0.3
				l1.1-1.1C86.4,223.6,86.4,223.4,86.4,223.2z"
            />
            <path
              class="st14"
              d="M111.9,218.7h0.7c1,0,1.9,0.8,1.9,1.9V232h-1.3v-11.4c0-0.4-0.3-0.7-0.7-0.7l0,0h-0.7L111.9,218.7z"
            />
          </g>
          <g>
            <path
              class="st14"
              d="M179.8,220.4c-2.9-0.3-5.8-0.1-8.9-0.1c1.2,2.1,0.8,3.9-0.8,5.4c-0.6,0.4-1.2,0.5-1.8,0.3
				c-1.1-0.5-1.9-1.6-2-2.8h1.2c0.2,0.8,0.5,2.1,1.7,1.6c1-0.5,1.6-1.7,1.1-2.8c-0.3-1.1-1.3-1.8-2.4-1.6h-0.1
				c-1.9,0.4-3.4,1.6-4.3,3.3c-0.7,1.7-2.1,2.9-3.9,3.3v1.7c0,2,0,2,2,2c0.7,0,1.8-0.1,2.3,0.3c1.5,1.2,3.1,2.5,2.4,4.8
				c0,0.3,0,0.7,0.1,0.9c0.7-0.1,1.8,0,1.9-0.3c0.3-0.9,0.5-1.9,0.6-2.8h10.4c-0.3-0.6-0.6-1.1-0.9-1.6c-1.8-1.5-2-3.4-1.8-5.5
				c0-0.3,0.6-0.6,0.8-0.8c0.4,1.8,0.3,3.6,1.2,4.6c1.3,1.4,2,3.4,1.6,5.3c0,0.4,0.1,0.7,0.1,0.9c2.3,0.1,2.2,0.1,2.3-2.1
				c0-0.9-0.2-1.9,0.7-2.7c2.7-2.3,3.1-6.4,0.7-9.2C183,221.4,181.4,220.5,179.8,220.4L179.8,220.4z"
            />
            <path
              class="st14"
              d="M187.6,220.5l-0.2,0.6c-0.2,0.7-0.5,1.1-0.9,1.6l0,0c0.3-0.5,0.4-1,0.4-1.6c0-0.3-0.2-0.6-0.4-0.8
				c-0.3-0.4-0.7-0.5-1-0.4c-0.5,0.3-0.7,0.7-0.6,1.3c0.1,0.5,0.3,1,0.5,1.4c-0.2,0.2-0.2,0.2-0.5,0.3l0.4,0.8
				c0.3,0,0.5-0.2,0.7-0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0.1,0.6,0,0.7-0.2c0.7-0.5,1.1-1.2,1.3-2.1c0.1-0.2,0.1-0.4,0.2-0.5
				L187.6,220.5z M185.9,222.2c-0.1-0.3-0.2-0.7-0.3-0.9c0-0.1,0-0.1,0-0.2s0.1-0.2,0.2-0.2h0.1
				C186.4,221.2,185.8,221.8,185.9,222.2L185.9,222.2z"
            />
          </g>
        </g>
      </switch>
    </svg>
  )
}

export default Diagram1
